<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <!-- <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2> -->

      <span @click="loginPage()">
        <b-img :to="{ name: 'login'}" :src="appLogoImage" alt="logo" style="width:190px" />
      </span>


    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Privacy Policy 🔏
        </h2>
        <p class="mb-2">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque quas doloribus corporis iusto eum magnam ad ullam eveniet maiores non. Vitae beatae rerum omnis aut voluptatum magnam nesciunt, obcaecati neque!
          <br><br>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque quas doloribus corporis iusto eum magnam ad ullam eveniet maiores non. Vitae beatae rerum omnis aut voluptatum magnam nesciunt, obcaecati neque!
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque quas doloribus corporis iusto eum magnam ad ullam eveniet maiores non. Vitae beatae rerum omnis aut voluptatum magnam nesciunt, obcaecati neque!
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque quas doloribus corporis iusto eum magnam ad ullam eveniet maiores non. Vitae beatae rerum omnis aut voluptatum magnam nesciunt, obcaecati neque!
          <br><br>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque quas doloribus corporis iusto eum magnam ad ullam eveniet maiores non. Vitae beatae rerum omnis aut voluptatum magnam nesciunt, obcaecati neque!


        </p>

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      appLogoImage : require('@/assets/images/logo/task-force-full.png'),
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  methods:{
    loginPage(){
      this.$router.push({ name: 'login'});
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
